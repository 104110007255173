exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ai-development-js": () => import("./../../../src/pages/ai-development.js" /* webpackChunkName: "component---src-pages-ai-development-js" */),
  "component---src-pages-chronic-conditions-js": () => import("./../../../src/pages/chronic-conditions.js" /* webpackChunkName: "component---src-pages-chronic-conditions-js" */),
  "component---src-pages-congestive-heart-failure-js": () => import("./../../../src/pages/congestive-heart-failure.js" /* webpackChunkName: "component---src-pages-congestive-heart-failure-js" */),
  "component---src-pages-custom-clinic-js": () => import("./../../../src/pages/custom-clinic.js" /* webpackChunkName: "component---src-pages-custom-clinic-js" */),
  "component---src-pages-demo-request-js": () => import("./../../../src/pages/demo-request.js" /* webpackChunkName: "component---src-pages-demo-request-js" */),
  "component---src-pages-digital-therapeutics-js": () => import("./../../../src/pages/digital-therapeutics.js" /* webpackChunkName: "component---src-pages-digital-therapeutics-js" */),
  "component---src-pages-fr-privacy-js": () => import("./../../../src/pages/fr/privacy.js" /* webpackChunkName: "component---src-pages-fr-privacy-js" */),
  "component---src-pages-fr-terms-js": () => import("./../../../src/pages/fr/terms.js" /* webpackChunkName: "component---src-pages-fr-terms-js" */),
  "component---src-pages-home-care-js": () => import("./../../../src/pages/home-care.js" /* webpackChunkName: "component---src-pages-home-care-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interoperability-js": () => import("./../../../src/pages/interoperability.js" /* webpackChunkName: "component---src-pages-interoperability-js" */),
  "component---src-pages-maternity-care-js": () => import("./../../../src/pages/maternity-care.js" /* webpackChunkName: "component---src-pages-maternity-care-js" */),
  "component---src-pages-mental-health-js": () => import("./../../../src/pages/mental-health.js" /* webpackChunkName: "component---src-pages-mental-health-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-other-areas-js": () => import("./../../../src/pages/other-areas.js" /* webpackChunkName: "component---src-pages-other-areas-js" */),
  "component---src-pages-patient-app-js": () => import("./../../../src/pages/patient-app.js" /* webpackChunkName: "component---src-pages-patient-app-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-professional-app-js": () => import("./../../../src/pages/professional-app.js" /* webpackChunkName: "component---src-pages-professional-app-js" */),
  "component---src-pages-questionnaires-js": () => import("./../../../src/pages/questionnaires.js" /* webpackChunkName: "component---src-pages-questionnaires-js" */),
  "component---src-pages-rd-js": () => import("./../../../src/pages/rd.js" /* webpackChunkName: "component---src-pages-rd-js" */),
  "component---src-pages-releases-js": () => import("./../../../src/pages/releases.js" /* webpackChunkName: "component---src-pages-releases-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-web-console-js": () => import("./../../../src/pages/web-console.js" /* webpackChunkName: "component---src-pages-web-console-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

